
import { IonHeader,  IonToolbar, IonButtons, IonButton, IonLabel, IonIcon, IonMenuButton } from '@ionic/vue';
import { call, location, mailOutline, locationOutline } from 'ionicons/icons';

import { defineComponent,  } from 'vue';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: 'HeaderComponent',
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonLabel,
    IonIcon,
    IonMenuButton,
    Dropdown
  },
  data() {
    return {
      wWidth: window.innerWidth,
      languages: [
                  {name: 'Español', code: 'es', flag:'assets/img/flags/espana.png'},
                  {name: 'English', code: 'en', flag:'assets/img/flags/reino-unido.png'},
                  {name: 'Deutsch', code: 'de', flag:'assets/img/flags/alemania.png'},
                ],
    }
  },
  setup() {
    return { call, location, mailOutline, locationOutline};
  },
  watch: {
      "$i18n.locale": {
          handler(locale) {
              localStorage.setItem("locale", locale);
          }
      }
  }
});
