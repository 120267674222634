<template>
  <ion-page>
    <ion-content :fullscreen="true">
    <Header></Header>
    
    <div class="background mb-lg">
      <div class="search-container">
        <h2 class="text-center text-light">{{ $t('Home.title')}}</h2>
        <AnimatedComponent animation-enter="animate__fadeInUp">
          <div class="search flex mt-xs">
            <div id="autocomplete" class="searchbar"/>
            <ion-button shape="round" color="primary" @click="search">
              <ion-label>{{ $t('General.buscar')}}</ion-label>
            </ion-button>
          </div>
        </AnimatedComponent>
      </div>
    </div>

    <div class="container">
      <section id="categorias" class="mb-xl">
        <swiper class="swiper-categorias inside-navigation" :modules="modules" :navigation="true" :breakpoints="{
              540: {
                  slidesPerView: 2,
              },
              720: {
                  slidesPerView: 2,
              },
              960: {
                  slidesPerView: 4,
              },
              1140: {
                  slidesPerView: 6,
                },
            }">

            <swiper-slide v-for="category in (categorias.length ? categorias : [0,0,0,0,0,0]).slice(0, 12)" :key="category.objectID">
              <CategoryCard :data="category"></CategoryCard>
            </swiper-slide>
        </swiper>
      </section>

      <section id="destacados" class="mb-xxl">
        <ion-grid>
          <ion-row>
            <ion-col size="12" size-lg="10"><h4>{{ $t('Home.destacadas')}}</h4></ion-col>
            <ion-col size="12" size-lg="2">
              <ion-button class="all-button" size="small" color="transparent" router-link="/empresas">
                <ion-label color="dark">{{ $t('Home.ver_todo')}}</ion-label>
                <ion-icon color="dark" :icon="arrowForwardOutline" slot="end"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row>
            <swiper class="swiper-empresas inside-navigation" :modules="modules" :navigation="true" :breakpoints="{
                  540: {
                      slidesPerView: 1,
                      spaceBetween: 32
                  },
                  720: {
                      slidesPerView: 1,
                      paceBetween: 32
                  },
                  960: {
                      slidesPerView: 2,
                  },
                  1140: {
                      slidesPerView: 3,
                    },
                }">
              <swiper-slide v-for="empresa in (empresas.length ? empresas : [0,0,0,0,0,0]).slice(12, 24)" :key="empresa.objectID">
                <EmpresaCard :data="empresa"></EmpresaCard>
              </swiper-slide>
            </swiper>
          </ion-row>
        </ion-grid>
      </section>

      <section id="restaurantes" class="mb-xxl">
        <ion-grid>
          <ion-row>
            <ion-col size="12" size-lg="3">
              <img v-if="patrocinios.length" class="patrocinio" :src="(patrocinios).slice(0, 1)[0].images[0][1024]" alt="Patrocinio"/>
            </ion-col>
            <ion-col size="12" size-lg="1"></ion-col>
            <ion-col size="12" size-lg="8">
              <ion-grid>
                <ion-row>
                  <ion-col size="12" size-lg="10"><h4 class="cards-section-title">{{ $t('Home.comer')}}</h4></ion-col>
                  <ion-col size="12" size-lg="2">
                    <ion-button class="all-button"  size="small" color="transparent" router-link="/empresas">
                      <ion-label color="dark">{{ $t('Home.ver_todo')}}</ion-label>
                      <ion-icon color="dark" :icon="arrowForwardOutline" slot="end"></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="12" size-lg="6" v-for="empresa in (empresas.length ? empresas.filter(x => foodCategories.includes(x.category.objectID)) : [0,0,0,0,0,0]).slice(0, 4)" :key="empresa.objectID">
                    <EmpresaCard :data="empresa"></EmpresaCard>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>

      <section id="empresas" class="mb-xxl">
        <ion-grid>
          <ion-row>
            <ion-col size="12" size-lg="10"><h4 class="cards-section-title">{{ $t('Home.empresas')}}</h4></ion-col>
            <ion-col size="12" size-lg="2">
              <ion-button class="all-button"  size="small" color="transparent" router-link="/empresas">
                <ion-label color="dark">{{ $t('Home.ver_todo')}}</ion-label>
                <ion-icon color="dark" :icon="arrowForwardOutline" slot="end"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12" size-lg="4" v-for="empresa in (empresas.length ? empresas : [0,0,0,0,0,0,0,0,0]).slice(0, 9)" :key="empresa.objectID">
              <EmpresaCard :data="empresa"></EmpresaCard>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>

      <section id="descarga" class="mb-xxl">
          <ion-grid class="banner p-xl">
            <ion-row>
              <ion-col size="12" size-lg="6">
                  <AnimatedComponent animation-enter="animate__fadeInUp">
                    <h3 class="text-light">{{ $t('Home.shorturl')}}</h3>
                    <ion-button shape="square" color="primary" class="mt-xxs" @click="installApp">
                      <ion-label>{{ $t('Home.shorturl_button')}}</ion-label>
                    </ion-button>
                  </AnimatedComponent>
              </ion-col>
              <ion-col size="12" size-lg="6"></ion-col>
            </ion-row>
          </ion-grid>
      </section>

      <section id="blog" class="mb-xxl" v-if="articles.length">
        <ion-grid>
          <ion-row>
            <ion-col size="12" size-lg="10"><h4>{{ $t('Home.noticias')}}</h4></ion-col>
            <ion-col size="12" size-lg="2">
              <ion-button class="all-button"  size="small" color="transparent" router-link="/blog">
                <ion-label color="dark">{{ $t('Home.ver_todo')}}</ion-label>
                <ion-icon color="dark" :icon="arrowForwardOutline" slot="end"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12" size-lg="6">
              <img :src="articles[0].images[0][1024]" class="blog-image-big"  :alt="articles[0].title[this.$i18n.locale]" />
            </ion-col>
            <ion-col size="12" size-lg="6" class="pt-sm">
              <AnimatedComponent animation-enter="animate__fadeInUp">
                <b class="text-uppercase text-secondary blog-category">{{ articles[0].category.name[this.$i18n.locale] }}</b>
                <h5 class="text-uppercase text-dark blog-title pt-xxs">{{ articles[0].title[this.$i18n.locale] }}</h5>
                <p class="text-uppercase blog-date">{{ fecha(articles[0].dateCreated) }}</p>
                <p class="blog-description-big pt-xxs" v-html="articles[0].description[this.$i18n.locale]"></p>
                <ion-button shape="square" color="primary" class="mt-xxs" :router-link="'/blog/' + articles[0].objectID">
                  <ion-label>{{ $t('General.seguir_leyendo')}}</ion-label>
                </ion-button>
              </AnimatedComponent>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12" class="mt-md subarticle" size-lg="4" v-for="article in (articles.length ? articles : [0,0,0,0,0,0,0,0,0]).slice(1, 4)" :key="article.objectID">
              <AnimatedComponent animation-enter="animate__fadeInUp">
                <ArticleCard :data="article"></ArticleCard>
              </AnimatedComponent>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>
    </div>

    <div class="deferredPrompt" v-if="deferredPrompt">
      <ion-item lines="none" style="margin-top: 8px;">
        <img src="/assets/icon/icon.png" slot="start" style="widht: 48px; height: 48px;"/>
        <ion-label text-wrap>
          <p class="text-light"><b>La Guía de Chiclana</b></p>
          <p class="text-light">laguiadechiclana.com</p>
        </ion-label>
      </ion-item>

      <ion-buttons style="margin: 16px; margin-top: 8px; justify-content: end;">
        <ion-button @click="dismissApp" fill="outline" color="light" style="margin-right: 16px; --border-radius: 4px;">
          <ion-label>Más Tarde</ion-label>
        </ion-button>
        <ion-button @click="installApp" fill="solid" color="primary" style="--border-radius: 4px;">
          <ion-label>Instalar App</ion-label>
        </ion-button>
      </ion-buttons>


    </div>
    
    <Footer></Footer>

    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonButton, IonLabel, IonGrid, IonRow, IonCol, IonIcon, IonButtons, IonItem } from '@ionic/vue';
import { arrowForwardOutline } from 'ionicons/icons';

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';
import 'swiper/css/navigation';
import '@ionic/vue/css/ionic-swiper.css';

import algoliasearch from 'algoliasearch/lite';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
const searchClient = algoliasearch(
  'DUPMPJI34E',
  '2ce550c8dec90d6b770f45b0c2e90c61'
);

import '@algolia/autocomplete-theme-classic';

import { defineComponent } from 'vue';

import AnimatedComponent from '@/components/AnimatedComponent.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

import CategoryCard from '@/components/lgdc/CategoryCard.vue';
import EmpresaCard from '@/components/lgdc/EmpresaCard.vue';
import ArticleCard from '@/components/lgdc/ArticleCard.vue';

import { mapActions, mapState } from 'vuex';
import moment from 'moment';

export default defineComponent({
  name: 'HomePage',
  components: {
    IonPage,
    IonContent,
    Header,
    Footer,
    Swiper,
    SwiperSlide,
    CategoryCard,
    EmpresaCard,
    ArticleCard,
    IonButton,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    AnimatedComponent,
    IonIcon,
    IonButtons,
    IonItem
  },
  data() {
    return {
      foodCategories: [
        'KC7tQiGc58BKuVCWoviH',
        'kl5oIqYUHk6Vxe75t82t',
        'P3ybJSlvVkZuvo8oVOm7',
        'VZDuwwgWbqooY2BuVqj9',
        '1b9IwSP6AgPwavCkdpi2',
        'h537hi4TkXzgYgf7lNkJ',
        '3PBTmFab8nTC2ke08oLs',
        'oDzBEg9A1A0ch3dqU8vH',
      ],
      searchText: '',
      deferredPrompt: null
    }
  },
  computed: {
    ...mapState(['empresas', 'categorias',  'articles', 'patrocinios'])
  },
  methods:{
    ...mapActions(['getEmpresas', 'getCategorias', 'getArticles', 'getPatrocinios']),

    search(){
      this.$router.push({ path: `/empresas/`, query: { s: this.searchText } });
    },

    fecha(date){
      const newDate = new Date(date);
      return moment(newDate).calendar();
    },

    async installApp(){
      this.deferredPrompt.prompt();
    },

    async dismissApp(){
      this.deferredPrompt = null;
    },
  },
  beforeMount() {
    this.getEmpresas();
    this.getCategorias();
    this.getArticles();
    this.getPatrocinios();
  },
  mounted(){
    autocomplete({
      container: '#autocomplete',
      detachedMediaQuery: 'none',
      placeholder: this.$t('Home.buscador'),
      onStateChange: ({state}) => this.searchText = state.query,
      onSubmit: ({state}) => this.$router.push({ path: `/empresas/`, query: { s: state.query } }),
      getSources() {
        return [
          {
            sourceId: 'querySuggestions',
            getItemInputValue: ({ item }) => item.query,
            getItems({ query }) {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: 'lgdc_empresas_query_suggestions',
                    query,
                    params: {
                      hitsPerPage: 6,
                    },
                  },
                ],
              });
            },
            templates: {
              item({ item, components }) {
                return item.query
              },
            },
          },
        ];
      },
    });
  },
  created(){
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
          console.log('test',e);
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  setup() {
    return { 
      modules: [Navigation], arrowForwardOutline
     };
  }
});
</script>

<style scoped>
  .background{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url('https://firebasestorage.googleapis.com/v0/b/la-guia-de-chiclana.appspot.com/o/web%2Fthumbnails%2Fchiclana2_2048x2048.webp?alt=media');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 100%;
    min-height: 640px;
    height: 640px;
  }

  .patrocinio{
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: top left;
    margin-bottom: 4rem;
  }

  .banner{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url('https://firebasestorage.googleapis.com/v0/b/la-guia-de-chiclana.appspot.com/o/web%2Fthumbnails%2Fchiclana1_2048x2048.webp?alt=media');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 100%;
    min-height: 360px;
    border-radius: 0.5rem;
  }

  /* Blog */
  .blog-image-big{
    width: 100%;
    margin: 1rem 0;
    border-radius: 0.5rem;
  }

  .blog-description-big{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5; /* number of lines to show */
              line-clamp: 5; 
      -webkit-box-orient: vertical;
  }


  /* Search */
  .search-container{
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: 1rem;
  }

  .search-container .search{
    border-radius: 2rem !important;
    background: var(--ion-color-light);
  }

  .search-container .search ion-button{
    height: 44px;
    margin: 0.5rem;
    width: 180px;
  }

  /* Cards Section Title */
  .cards-section-title{
    padding-left: 0;
  }

  .all-button{
    float: left;
    --padding-start: 0;
  }

  .deferredPrompt{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--ion-color-tertiary);
    z-index: 999;
  }

  @media (max-width: 540px) {

  }

  @media (min-width: 540px) and (max-width: 720px) {

  }

  @media (min-width: 720px) and (max-width: 960px) {

  }

  @media (min-width: 960px) and (max-width: 1440px) {
    .patrocinio{
      margin-bottom: 0;
    }

    .blog-image-big{
      width: calc(100% - 64px);
      margin-right: 3rem;
    }

    .search-container{
      max-width: 1080px;
    }

    .all-button{
      float: right;
    }

     .cards-section-title{
      padding-left: 1rem;
    }

    .subarticle:first-child:deep(.card-article){
      margin-left: 0 !important;
    }
  }

  @media (min-width: 1440px) {
    .patrocinio{
      margin-bottom: 0;
    }

    .blog-image-big{
      width: calc(100% - 64px);
      margin-right: 3rem;
    }

    .search-container{
      max-width: 1080px;
    }

    .all-button{
      float: right;
    }

    .cards-section-title{
      padding-left: 1rem;
    }

    .subarticle:first-child:deep(.card-article){
      margin-left: 0 !important;
    }
  }

</style>