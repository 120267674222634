
import { IonSkeletonText } from '@ionic/vue';
import { call, location } from 'ionicons/icons';

import { defineComponent,  } from 'vue';

export default defineComponent({
  name: 'EmpresaCardComponent',
  props: ['data'],
  components: {
      IonSkeletonText,
  },
  data() {
    return {
      wWidth: window.innerWidth
    }
  },
  setup() {
    return { call, location};
  }
});
