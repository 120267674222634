
import { IonSkeletonText, IonButton } from '@ionic/vue';
import { call, location } from 'ionicons/icons';
import moment from 'moment';

import { defineComponent,  } from 'vue';

export default defineComponent({
  name: 'ArticleCardComponent',
  props: ['data'],
  components: {
      IonSkeletonText,
      IonButton
  },
  data() {
    return {
      wWidth: window.innerWidth
    }
  },
  methods: {
    fecha(date){
      const newDate = new Date(date);
      return moment(newDate).calendar();
    }
  },
  setup() {
    return { call, location};
  }
});
