import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/empresas',
    name: 'Empresas',
    component: () => import('@/views/Empresas.vue'),
  },
  {
    path: '/empresas/:id',
    name: 'Empresa',
    component: () => import('@/views/Empresa.vue'),
  },
  {
    path: '/sobre',
    name: 'About',
    component: () => import('@/views/About.vue'),
  },
  {
    path: '/contacto',
    name: 'Contact',
    component: () => import('@/views/Contact.vue'),
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('@/views/Blog.vue'),
  },
  {
    path: '/blog/:id',
    name: 'Article',
    component: () => import('@/views/Article.vue'),
  },

  {
    path: '/aviso-legal',
    name: 'LegalWarning',
    component: () => import('@/views/legal/LegalWarning.vue'),
  },
  {
    path: '/privacidad',
    name: 'Privacity',
    component: () => import('@/views/legal/Privacity.vue'),
  },
  {
    path: '/cookies',
    name: 'Cookies',
    component: () => import('@/views/legal/Cookies.vue'),
  },

  { 
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
