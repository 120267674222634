import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0fb40372"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card-empresa"
}
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "texto" }
const _hoisted_4 = { class: "nombre text-dark" }
const _hoisted_5 = { class: "categoria text-dark" }
const _hoisted_6 = {
  key: 1,
  class: "card-empresa"
}
const _hoisted_7 = { class: "texto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.data)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_router_link, {
            to: '/empresas/' + _ctx.data.objectID
          }, {
            default: _withCtx(() => [
              (_ctx.data.images && _ctx.data.images.length)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "imagen",
                    src: _ctx.data.images[0][512],
                    alt: _ctx.data.name
                  }, null, 8, _hoisted_2))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_router_link, {
            to: '/empresas/' + _ctx.data.objectID
          }, {
            default: _withCtx(() => [
              (!_ctx.data.images || !_ctx.data.images.length)
                ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                    key: 0,
                    class: "imagen",
                    style: {"width":"100%","cursor":"pointer"}
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["to"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_link, {
              to: '/empresas/' + _ctx.data.objectID
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_4, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.data.name.toLowerCase()), 1)
                ])
              ]),
              _: 1
            }, 8, ["to"]),
            _createVNode(_component_router_link, {
              to: '/empresas?c=' + _ctx.data.category.objectID
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.data.category.name[this.$i18n.locale]), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.data)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_ion_skeleton_text, {
            animated: "",
            class: "imagen",
            style: {"width":"100%"}
          }),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_ion_skeleton_text, {
              animated: "",
              style: {"width":"70%"}
            }),
            _createVNode(_component_ion_skeleton_text, {
              animated: "",
              style: {"width":"40%"}
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}