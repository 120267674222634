
import {  IonFooter, IonButton } from '@ionic/vue';
import { call, location, mail, logoFacebook, logoInstagram } from 'ionicons/icons';

import { defineComponent,  } from 'vue';

import InputText from 'primevue/inputtext';

import useVuelidate from '@vuelidate/core'
import { required, email } from '@/utils/i18n-validators'

export default defineComponent({
  name: 'FooterComponent',
  components: {
    IonFooter,
    InputText,
    IonButton
  },
  data() {
    return {
      anno: new Date().getFullYear(),
      subscribe: {
        email: '',
      },
    }
  },
  validations() {
    return {
      subscribe: {
        email: { required, email },
      },
    }
  },
  methods: {
    makeSubscribe(){
      console.log('proximamente..');
    }
  },
  setup() {
    return { v$: useVuelidate(), call, location, mail, logoFacebook, logoInstagram };
  }
});
