
import { IonCard, IonCardHeader, IonCardTitle, IonSkeletonText } from '@ionic/vue';
import { call, location } from 'ionicons/icons';

import { defineComponent,  } from 'vue';

export default defineComponent({
  name: 'CategoryCardComponent',
  props: ['data'],
  components: {
      IonCard,
      IonCardHeader,
      IonCardTitle,
      IonSkeletonText
  },
  data() {
    return {
      wWidth: window.innerWidth
    }
  },
  setup() {
    return { call, location};
  }
});
