/* eslint-disable no-unused-vars */
import { createStore } from 'vuex'
import algoliasearch from 'algoliasearch/lite';
import { db } from '@/firebaseConfig';


export const store = createStore({
    state: {
        empresas: [],
        empresa: {},
        categorias: [],

        patrocinios: [],

        articles: [],
        article: {},
        blogCategories: [],
        blogCategory: {},
    },
    mutations: {
        // LGDC
        setEmpresas(state, payload){
            state.empresas = payload
        },
        setEmpresa(state, payload){
            state.empresa = payload
        },
        setCategorias(state, payload){
            state.categorias = payload
        },

        setPatrocinios(state, payload){
            state.patrocinios = payload
        },

        setArticles(state, payload){
            state.articles = payload
        },
        setArticle(state, payload){
            state.article = payload
        },

        setBlogCategories(state, payload){
            state.blogCategories = payload
        },
        setBlogCategory(state, payload){
            state.blogCategory = payload
        },
    },
    actions: {
        // LGDC

        // Empresas
        async getEmpresas({commit}){
            if(!this.state.empresas.length){
                const searchClient = algoliasearch(
                  'DUPMPJI34E',
                  '2ce550c8dec90d6b770f45b0c2e90c61'
                );
      
                let data = [];
      
                const index = searchClient.initIndex("lgdc_empresas");
                await index
                    .search('')
                    .then(async (res) => {
                        data = res.hits;
      
                        if(res.nbPages > 1){
                          for (let page = 1; page <= res.nbPages; page++) {
                            await index.search('', {page: page})
                              .then((res2) => {
                                  data.push(...res2.hits);
                              })
                              .catch(err => {
                                  console.log(err);
                              });
                          }
                        }
      
                    })
                    .catch(err => {
                        console.log(err);
                    });

                    // Randomize
                    for (let i = data.length - 1; i > 0; i--) {
                        const j = Math.floor(Math.random() * (i + 1));
                        [data[i], data[j]] = [data[j], data[i]];
                    }

                    data = data.filter(x => x.active === true);
      
                  commit('setEmpresas', data);
              }
        },
        async getEmpresa({commit}, id){
            if(!this.state.empresas.length){
                const res = await db.collection('/evolbe-modules/lgdc/empresas').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setEmpresa', data);
            } else {
                const data = this.state.empresas.find(x => x.objectID === id)
                commit('setEmpresa', data);
            }
        },

        // Categorias
        async getCategorias({commit}){
            if(!this.state.categorias.length){
                const res = await db.collection('/evolbe-modules/lgdc/categorias').where('active', '==', true).get();
                    const data = [];
                    res.forEach(item => {
                    const e = {
                        objectID: item.id,
                        ...item.data()
                    }
                        data.push(e);
                    })

                    // Randomize
                    for (let i = data.length - 1; i > 0; i--) {
                        const j = Math.floor(Math.random() * (i + 1));
                        [data[i], data[j]] = [data[j], data[i]];
                    }

                    commit('setCategorias', data);
            }
        },

        // Patrocinios
        async getPatrocinios({commit}){
            if(!this.state.patrocinios.length){
                let data;
                await db.collection('/evolbe-modules/lgdc/patrocinios').where('active', '==', true).onSnapshot(res => {
                    data = [];
                    res.forEach(item => {

                    const e = {
                        objectID: item.id,
                        ...item.data()
                    }
                        data.push(e);
                    })

                    // Randomize
                    for (let i = data.length - 1; i > 0; i--) {
                        const j = Math.floor(Math.random() * (i + 1));
                        [data[i], data[j]] = [data[j], data[i]];
                    }

                    commit('setPatrocinios', data);
                });

            }
        },

        // Articles
        async getArticles({commit}){
            if(!this.state.articles.length){
                const res = await db.collection('/evolbe-modules/blog/articles').where('active', '==', true).orderBy('dateCreated', 'desc').get();
                const data = [];
                res.forEach(item => {
                const e = {
                    objectID: item.id,
                    ...item.data()
                }
                    data.push(e);
                })

                commit('setArticles', data);
            }
        },
        async getArticle({commit}, id){
            if(!this.state.articles.length){
                const res = await db.collection('/evolbe-modules/blog/articles').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setArticle', data);
            } else {
                const data = this.state.articles.find(x => x.objectID === id)
                commit('setArticle', data);
            }
        },

        // Blog Categories
        async getBlogCategories({commit}){
            if(!this.state.blogCategories.length){
                const res = await db.collection('/evolbe-modules/blog/categories').where('active', '==', true).orderBy('order', 'asc').get();
                    const data = [];
                    res.forEach(item => {
                    const e = {
                        objectID: item.id,
                        ...item.data()
                    }
                        data.push(e);
                    })

                    commit('setBlogCategories', data);
            }
        },
        async getBlogCategory({commit}, id){
            if(!this.state.blogCategories.length){
                const res = await db.collection('/evolbe-modules/blog/categories').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setBlogCategory', data);
            } else {
                const data = this.state.blogCategories.find(x => x.objectID === id)
                commit('setBlogCategory', data);
            }
        },
    },
    getters: {
    },
    modules: {},
});
  