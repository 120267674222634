import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDhv8BiWRyNufREd8lcUSpcHoD9AKEgrQ0",
  authDomain: "la-guia-de-chiclana.firebaseapp.com",
  databaseURL: "https://la-guia-de-chiclana.firebaseio.com",
  projectId: "la-guia-de-chiclana",
  storageBucket: "la-guia-de-chiclana.appspot.com",
  messagingSenderId: "208927540910",
  appId: "1:208927540910:web:dfb876ce8200afe5ad4f3f",
  measurementId: "G-Z46516KVXW"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore();
firebaseApp.firestore().settings({
  ignoreUndefinedProperties: true,
});
export const auth = firebaseApp.auth();
