
import { IonApp, IonRouterOutlet, createAnimation } from '@ionic/vue';
import { defineComponent } from 'vue';

import Toast from 'primevue/toast';
import Menu from '@/components/Menu.vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    Toast,
    Menu
  },
  methods: {
    navAnimation(ElementRef, opts){
      const enterAnimation = createAnimation()
        .addElement(opts.enteringEl)
        .fromTo('opacity', '0', '1')
        .delay(100)
        .duration(50);

      const exitAnimation = createAnimation()
        .addElement(opts.leavingEl)
        .fromTo('opacity', '1', '0')
        .duration(50);

      const rootAnimation = createAnimation();

      rootAnimation.addAnimation([exitAnimation,enterAnimation]);

      return rootAnimation;
    },
  },
});
