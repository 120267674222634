<template>
  <ion-menu side="end" menu-id="mainMenu" content-id="main" type="push" class="mainMenu hide-xl hide-lg">
    <ion-content class="main-nav">
      <div class="menu-container">
        <ion-header class="ion-no-border" id="header">
            <ion-toolbar color="transparent">
              <ion-buttons slot="end">
                <ion-button @click="close">
                  <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>

         <ion-list lines="none" class="modules-list">
            <ion-menu-toggle auto-hide="false">
              <ion-item button @click="$router.push({ path: `/` })" detail="false">
                <ion-label color="light"><strong>{{ $t('Menu.inicio')}}</strong></ion-label>
                <ion-icon :icon="chevronForwardOutline" color="light" slot="end" item-right></ion-icon>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item button @click="$router.push({ path: `/empresas` })" detail="false">
                <ion-label color="light"><strong>{{ $t('Menu.empresas')}}</strong></ion-label>
                <ion-icon :icon="chevronForwardOutline" color="light" slot="end"></ion-icon>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item button @click="$router.push({ path: `/sobre` })" detail="false">
                <ion-label color="light"><strong>{{ $t('Menu.sobre_nosotros')}}</strong></ion-label>
                <ion-icon :icon="chevronForwardOutline" color="light" slot="end"></ion-icon>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item button @click="$router.push({ path: `/blog` })" detail="false">
                <ion-label color="light"><strong>{{ $t('Menu.blog')}}</strong></ion-label>
                <ion-icon :icon="chevronForwardOutline" color="light" slot="end"></ion-icon>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-button shape="round" expand="full" color="light" style="margin: 2rem 0;" fill="outline" target="_blank" @click="$router.push({ path: `/contacto` })" >
                <ion-icon :icon="mailOutline" color="light" slot="start"></ion-icon>
                <ion-label color="light"><strong>{{ $t('Menu.contacto')}}</strong></ion-label>
              </ion-button>
            </ion-menu-toggle>
        </ion-list>
      </div>
      
    </ion-content>
  </ion-menu>
</template>

<script>
import { IonMenu, IonList, IonItem, IonMenuToggle, menuController, IonButtons, IonButton, IonLabel, IonIcon, IonHeader, IonToolbar, IonContent } from '@ionic/vue';
import { chevronForwardOutline, bagHandleOutline, closeOutline, callOutline, calendarOutline, mailOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import Tooltip from 'primevue/tooltip';

export default defineComponent({
  name: 'MenuComponent',
  components: {
    IonMenu,
    IonList,
    IonItem,
    IonMenuToggle,
    IonButtons,
    IonButton,
    IonLabel,
    IonIcon,
    IonHeader,
    IonToolbar,
    IonContent
  },
  directives: {
    'tooltip': Tooltip
  },
  data() {
    return {
      openList: [],
    }
  },
  computed: {
      ...mapState(['evolbeUser'])
  },
  methods:{
    close(){
      menuController.close();
    }
  },
  setup() {
    return { chevronForwardOutline, bagHandleOutline, closeOutline, callOutline, calendarOutline, mailOutline };
  }
});
</script>

<style>

  .mainMenu{
    --background: var(--ion-color-primary-shade);
    z-index: 800;
  }

  .main-nav{
    --background: var(--ion-color-primary-shade);
    --color: var(--ion-color-light);
  }

  .menu-container{
    padding: 2rem;
  }
  
  .modules-list{
    padding: 1rem;
  }

  .modules-list ion-label{
      text-transform: uppercase;
  }

  .hidden{
    display: none;
  }

  #header{
    height: 5rem;
  }

</style>