import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.data)
      ? (_openBlock(), _createBlock(_component_ion_card, {
          key: 0,
          class: "card-categoria",
          button: "",
          "router-link": '/empresas?c=' + _ctx.data.objectID
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_header, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.data.name[this.$i18n.locale]), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["router-link"]))
      : _createCommentVNode("", true),
    (!_ctx.data)
      ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
          key: 1,
          animated: "",
          class: "card-categoria",
          style: {"border-radius":"0.5rem"}
        }))
      : _createCommentVNode("", true)
  ], 64))
}