import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0716e1d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card-article"
}
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "texto" }
const _hoisted_4 = { class: "text-uppercase text-secondary blog-category" }
const _hoisted_5 = { class: "text-uppercase text-dark blog-title pt-xxs" }
const _hoisted_6 = { class: "text-uppercase blog-date" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 1,
  class: "card-article"
}
const _hoisted_9 = { class: "texto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.data)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_router_link, {
            to: '/blog/' + _ctx.data.objectID
          }, {
            default: _withCtx(() => [
              (_ctx.data.images && _ctx.data.images.length)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "imagen",
                    src: _ctx.data.images[0][512],
                    alt: _ctx.data.name
                  }, null, 8, _hoisted_2))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["to"]),
          (!_ctx.data.images || !_ctx.data.images.length)
            ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                key: 0,
                class: "imagen",
                style: {"width":"100%"}
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("b", _hoisted_4, _toDisplayString(_ctx.data.category.name[this.$i18n.locale]), 1),
            _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.data.title[this.$i18n.locale]), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.fecha(_ctx.data.dateCreated)), 1),
            _createElementVNode("p", {
              class: "blog-description pt-xxs",
              innerHTML: _ctx.data.description[this.$i18n.locale]
            }, null, 8, _hoisted_7),
            _createVNode(_component_ion_button, {
              shape: "square",
              color: "primary",
              class: "mt-xxs",
              "router-link": '/blog/' + _ctx.data.objectID
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('General.seguir_leyendo')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["router-link"])
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.data)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_ion_skeleton_text, {
            animated: "",
            class: "imagen",
            style: {"width":"100%"}
          }),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_ion_skeleton_text, {
              animated: "",
              style: {"width":"40%"}
            }),
            _createVNode(_component_ion_skeleton_text, {
              animated: "",
              style: {"width":"70%"}
            }),
            _createVNode(_component_ion_skeleton_text, {
              animated: "",
              style: {"width":"100%"}
            }),
            _createVNode(_component_ion_skeleton_text, {
              animated: "",
              style: {"width":"90%"}
            }),
            _createVNode(_component_ion_skeleton_text, {
              animated: "",
              style: {"width":"80%"}
            }),
            _createVNode(_component_ion_skeleton_text, {
              animated: "",
              style: {"width":"20%","height":"36px"}
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}